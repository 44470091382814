import { respondTo } from '@/respondTo';
import Styled, { keyframes } from 'styled-components';

export const NotFoundImageWrapper = Styled.div`    
    width: 24.375rem;
    margin-top: 1rem;

    ${respondTo.tablet_portrait`
        height: 37.5rem;
        width: 42.25rem;
        margin-top: 5rem;
        
        a, button {
            width: 100% !important;
        }
    `} 
`;

const MoveUpDown = keyframes`
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-1.5rem);

        ${respondTo.tablet_portrait`
            transform: translateY(-0.5rem);
        `}        
    }
`;

export const ImgWrapper = Styled.div`
    position: relative;
    width: 21.875rem;
    height: 20.16556rem;     
    -webkit-animation: ${MoveUpDown} 5s infinite;
    animation: ${MoveUpDown} 5s infinite;

    ${respondTo.tablet_portrait`
        width: 27.71931rem;
        height: 25.55319rem;    
    `}
`;

export const NotFoundInfoWrapper = Styled.div`    
    width: 24.375rem;
        
    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        gap: 1.25rem;

        ${respondTo.tablet_portrait`
            gap: 1.25rem; 
        `}

        ${respondTo.desktop`
            gap: 2.5rem; 
        `}
    }

    ${respondTo.tablet_portrait`
        width: 26.125rem;        
        a, button {
            width: 100% !important;
        }
    `}

    ${respondTo.desktop` 
        width: 42.25rem;
        a, button {
            width: 35rem !important;
        }
    `}
`;

export const MainContainer = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center; 
    gap: 1.25rem;
    width: 100%;
    padding: 1.25rem;
    
    ${NotFoundImageWrapper}, ${NotFoundInfoWrapper} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        gap: 1.25rem;

        ${respondTo.tablet_portrait`
            gap: 2.5rem; 
        `}
    }

    ${respondTo.tablet_portrait`              
        flex-direction: row;        
        padding: 4.375rem;
    `}        
`;
