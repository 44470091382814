import { ChevronLeft } from "@mui/icons-material";
import { Link, Typography } from "@mui/material";
import { ButtonMui } from "../ui";
import * as Styled from "./notFound.styles";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import { useTheme } from "@cellectenergy/cellect-react-components";

const NotFoundSection = () => {
  const { t } = useTranslation("global");
  const theme = useTheme();

  return (
    <Styled.MainContainer>
      <Styled.NotFoundImageWrapper>
        <Styled.ImgWrapper>
          <Image
            src="404.svg"
            alt="cellect-energy 404"
            fill
            style={{ objectFit: "cover" }}
            quality={100}
          />
        </Styled.ImgWrapper>
      </Styled.NotFoundImageWrapper>
      <Styled.NotFoundInfoWrapper>
        <div>
          <Typography
            color={theme.palette.common.corporateColors.primary.white}
            variant="display-large"
            fontWeight={500}
          >
            404
          </Typography>
          <Typography
            color={theme.palette.common.corporateColors.primary.white}
            variant="label-large"
          >
            {t("NotFound.notFountTitle")}
          </Typography>
        </div>
        <Link href="/">
          <ButtonMui
            size="large"
            variant="contained"
            startIcon={<ChevronLeft />}
            sx={{
              width: { xs: "100%", md: "480px", lg: "560px" },
              backgroundColor:
                theme.palette.common.corporateColors.secondary.turquoise,
              color: theme.palette.common.corporateColors.primary.midNightBlue,
            }}
          >
            {t("NotFound.goBackCta")}
          </ButtonMui>
        </Link>
      </Styled.NotFoundInfoWrapper>
    </Styled.MainContainer>
  );
};

export default NotFoundSection;
