import NotFoundSection from "@/components/notFound";
import { FullWidthLayout, SectionLayout } from "@/layouts";
import MainLayout from "@/layouts/MainLayout";
import { useTheme } from "@cellectenergy/cellect-react-components";

const NotFoundPage = () => {
  const theme = useTheme();
  return (
    <MainLayout
      bgTheme={theme.palette.common.corporateColors.primary.midNightBlue}
      navbarTheme="light"
      title="404: Page Not Found"
      description="We couldn't find what you were looking for"
    >
      <FullWidthLayout
        id="not-found"
        bgColor={theme.palette.common.corporateColors.primary.midNightBlue}
      >
        <SectionLayout className="animate__animated animate__fadeIn">
          <NotFoundSection />
        </SectionLayout>
      </FullWidthLayout>
    </MainLayout>
  );
};

export default NotFoundPage;
