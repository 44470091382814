import { SxProps } from "@mui/material";
import Styled from "styled-components";
type SectionLayoutProps = {
  children: React.ReactNode;
  id?: string;
  bgColor?: string;
  sx?: SxProps;
  className?: string;
};

export const SectionLayoutContainer = Styled.section<SectionLayoutProps>`
  width: 100%;
  max-width: 1440px;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
  display:flex;
  justify-content:center;
  position:relative;
  margin:0 auto;
  `;

export const SectionLayout = ({
  children,
  id,
  bgColor,
  className,
}: SectionLayoutProps) => {
  return (
    <SectionLayoutContainer bgColor={bgColor} id={id} className={className}>
      {children}
    </SectionLayoutContainer>
  );
};
