import Styled from "styled-components";

type AllWidthLayoutProps = {
  children: React.ReactNode;
  id?: string;
  bgColor?: string;
  borderColor?: string;
  styles?: React.CSSProperties;
};

export const LayoutContainer = Styled.section<AllWidthLayoutProps>`
  width: 100%;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
  display:flex;
  justify-content:center;
  position:relative;
  margin:0 auto;
    border-top: ${({ borderColor }: any) =>
      borderColor ? `1px solid ${borderColor}` : ""};
  `;

export const FullWidthLayout = ({
  children,
  id,
  bgColor,
  borderColor,
  styles,
}: AllWidthLayoutProps) => {
  return (
    <LayoutContainer
      style={styles}
      bgColor={bgColor}
      id={id}
      borderColor={borderColor}
    >
      {children}
    </LayoutContainer>
  );
};
